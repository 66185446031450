<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Add Category</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form class="py-5">
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-switch
                v-model="subcategory"
                label="Sub Category"
                class="ma-0"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <treeselect 
                class="my-6"
                v-if="subcategory"
                v-model="form_data.parent" 
                :multiple="false" 
                :options="parent_category"
                name="demo"
                :default-expand-level="2"
              >
                <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
                  {{ node.raw.name }}
                </label>
                <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
              </treeselect>
               <!-- <v-select
                  :items="parent_category"
                  item-text="name"
                  item-value="id"
                  v-if="form_data.is_parent"
                  v-model="form_data.parent_category"
                  :error-messages="form_error.parent_category"
                  label="Select Parent Category"
                  dense
                  outlined
                >
                  <template v-slot:item="{ item }">
                    
                   {{item.name}}
                  </template>
                
                </v-select> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="Category"
                v-model="form_data.name"
                :error-messages="form_error.name"
                outlined
                dense
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4 mt-3"
            color="secondary"
            @click="addCategory"
            :loading="loading"
            :disabled="loading"
          >submit</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/category">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
  // import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      subcategory : false,
      form_data: {
        is_parent : false,
      },
      form_error: {},
      loading: false,
      data: {},
      parent_category : []
    };
  },
  components: { Treeselect },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getParentCategory(){
      this.form_data.is_parent = !this.subcategory
      this.$axios.get('admin/category/get_parent?pagination=false&subcategory=true').then((response)=>{
        if(response?.data?.data){
          this.parent_category = response.data.data
        }
        // console.log('response',response)
      })
    },
    addCategory() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`admin/category/add`, _self.form_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/category");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
  mounted(){
    this.getParentCategory()
  }
};
</script>
<style>
.vue-treeselect__control {
  height: 40px !important;
}
.vue-treeselect__placeholder, .vue-treeselect__single-value {
  line-height: 40px !important;
}
</style>